import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';
import { Link } from 'gatsby';

const InformationenZuCorona = ({ data, location, ...props }) => {
	return (
		<Layout
			location={location}
			title="Informationen zur Corona-Krise"
			noSlider
			{...props}
		>
			<p>
				Wir sind auch in Coronazeiten für Sie da.
				<br />
				<strong>
					Bitte beachten Sie: Unsere Praxis ist zu den gewohnten
					Zeiten geöffnet.
				</strong>
			</p>
			<p>
				Um die gesetzlich vorgeschriebenen Sicherheitsmaßnahmen
				einhalten zu können, bitten wir folgendes zu beachten:
			</p>
			<ol>
				<li>Maskenpflicht</li>
				<li>Keine Begleitpersonen in der Praxis</li>
				<li>
					Sprechstunde nur nach vorheriger Terminvereinbarung,
					bevorzugt über das{' '}
					<Link to="/kontakt">Kontaktformular</Link>
				</li>
			</ol>
			<p>
				<strong>
					Bei grippeähnlichen Symptomen suchen Sie unsere Praxis bitte
					nicht auf und sagen Sie Ihre Termine ab bzw. verlegen den
					Termin.
				</strong>
			</p>
			<p>
				<strong>Dr. med. Michael Krokowski</strong>
				<br />
				<strong>mit dem gesamten Praxisteam.</strong>
			</p>
		</Layout>
	);
};

export default InformationenZuCorona;
